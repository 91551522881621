import { useEffect, useRef } from 'react'
import { checkWallet, selectWalletHooks } from '../walletHelper'
import { useWeb3React } from '@web3-react/core'
import { ToastType, platformSupportedChainId } from 'shared/constants'
import { useAppDispatch, useAppSelector } from 'src/logic/redux/hooks'
import {
  setShowWrongNetworkModal,
  showToast,
} from 'src/logic/redux/slices/uiSlice'
import detectEthereumProvider from '@metamask/detect-provider'
import { WalletTypeEnum } from 'src/logic/redux/slices/userSlice'

export const useConnectWallet = () => {
  const {
    user: { walletType },
  } = useAppSelector((state) => state.userReducer)
  //Define Variables
  let chainId: number | undefined
  let active: boolean
  let address: any
  let connectedWallet: any
  let activating: boolean
  let provider: any
  let signer: any
  const error = useRef()
  const dispatch = useAppDispatch()

  const { hooks: PriorityHook } = useWeb3React()
  const hook = selectWalletHooks()

  const { useChainId, useIsActive, useAccounts, useIsActivating, useProvider } =
    hook

  const { usePriorityConnector } = PriorityHook
  const { account: web3Account } = useWeb3React()

  // Set Hooks into Variables
  chainId = useChainId()
  active = useIsActive()
  address = useAccounts()
  connectedWallet = usePriorityConnector()
  activating = useIsActivating()
  provider = useProvider()
  signer = provider?.getSigner()

  const account = address ? address[0] : ''
  const activate = async (connector?: any) => {
    const metamaskProvider = await detectEthereumProvider()
    checkWallet(connector)
    connector
      .activate(platformSupportedChainId)
      .then(() => { })
      .catch((connectError: any) => {
        if (connectError?.code === 4902) {
          dispatch(setShowWrongNetworkModal(true))
        }

        if (!metamaskProvider && walletType === WalletTypeEnum.METAMASK) {
          dispatch(
            showToast({
              message: 'Metamask not found!',
              toastType: ToastType.error,
            })
          )
        }

        error.current = connectError
        console.error('Activate Func error', connectError.message)
      })
  }

  useEffect(() => {
    if (web3Account) {
      localStorage.setItem('address', web3Account)
    }
  }, [web3Account])

  const deactivate = (connector?: any) => {
    try {
      if (connector?.deactivate) {
        connector.deactivate()
        connector.resetState()
      } else {
        connector.resetState()
      }
    } catch (error) { }
  }

  return {
    activate,
    deactivate,
    chainId,
    active,
    account,
    connectedWallet,
    activating,
    error: error?.current,
    provider,
    signer,
  }
}
