import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import userReducer from './slices/userSlice'
import uiReducer from './slices/uiSlice'
import nftReducer from './slices/nftsSlice'
import socketSlice from './slices/socketSlice'
import tokenPricesSlice from './slices/tokenPricesSlice'

const rootReducer = combineReducers({
  userReducer,
  uiReducer,
  nftReducer,
  authReducer,
  socketSlice,
  tokenPricesSlice
})

export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware()
})

export type AppDispatch = typeof store.dispatch

export default store
