import { toast } from 'react-toastify'
import { useEffect } from 'react'
import Image from 'next/image'

import { FlexRow } from 'styles/styled'
import * as Styles from './style'
import WarningSvg from 'public/assets/landing/warning.svg'
import LargeCloseSvg from 'public/assets/landing/largeClose.svg'
import { useAppDispatch, useAppSelector } from 'src/logic/redux/hooks'
import { showToast } from 'src/logic/redux/slices/uiSlice'
import { ToastType } from 'shared/constants'
import useWindowDimensions from 'shared/hooks/useWindowDimensions'
import { ErrorIcon, TickSvg } from 'shared/images'

const CustomToast = () => {
  const dispatch = useAppDispatch()
  const { toastObj } = useAppSelector((state) => state.uiReducer)

  const { isMobile } = useWindowDimensions()

  useEffect(() => {
    if (toastObj?.message) {
      toast(<Notification />, {
        onClose: () => {
          dispatch(
            showToast({
              message: '',
              toastType: ToastType.error,
            })
          )
        },
      })
    }
  }, [toastObj?.message])
  return (
    <Styles.ToastCont
      autoClose={3000}
      toastClassName='toast-body'
      position={isMobile ? 'top-center' : 'top-right'}
    />
  )
}

export default CustomToast

const Notification = () => {
  const { toastObj } = useAppSelector((state) => state.uiReducer)
  const getToastIcon = () => {
    switch (toastObj?.toastType) {
      case ToastType.error:
        return ErrorIcon
      case ToastType.warning:
        return WarningSvg
      case ToastType.success:
        return TickSvg

      default:
        return ''
    }
  }
  return (
    <Styles.NotificationCont>
      <FlexRow gap='16px'>
        <Image src={getToastIcon()} alt='toast-icon' />
        <p>{toastObj?.message}</p>
      </FlexRow>
      <Styles.CloseIcon src={LargeCloseSvg} alt='close-icon' />
    </Styles.NotificationCont>
  )
}
