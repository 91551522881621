import axios from 'axios'
import { getConfig } from 'config'
import { StorageKey } from 'shared/constants'

export const getToken = () => {
  if (typeof window == 'undefined') return null
  return localStorage.getItem(StorageKey.token)
    ? localStorage.getItem(StorageKey.token)
    : null
}

export const getAuthorizationHeader = () =>
  getToken() ? `Bearer ${getToken()}` : null

export const axiosInstance = axios.create({
  baseURL: getConfig().apiUrl,
})

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = getAuthorizationHeader();
  return config;
});
