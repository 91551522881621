import { getConfig } from 'config'

export const StorageKey = {
  token: 'token',
}

export const VTRCONNECT_LINK = 'https://www.vtrconnect.com'

export const platformSupportedChainId = getConfig().chainId

export enum ToastType {
  success = 'success',
  error = 'error',
  warning = 'warning',
}
