export const colors = {
  primary: '#2393B8',
  secondary: '#000C1C',
  modalOverlay: 'rgba(25, 59, 77, 0.7)',
  modalBackground: 'rgba(0, 12, 28, 0.8)',
  inputBg: 'rgba(26, 26, 26, 0.8)',
  darkBg: '#04020d',
  buttonBg: 'rgba(35, 147, 184, 0.2)',
  red: 'red',
  borderColor: 'rgba(255, 255, 255, 0.2)',
  borderHighlight: 'rgba(255, 255, 255, 0.6)',
  white: '#fff',
  lightWhite: 'rgba(255, 255, 255, 0.2)',
  lightWhite1: '#BEC5CB',
  error: '#EF466F',
  warning: '#FCCC75',
  success: '#06C270',
}

export const screenSizes = {
  XS: 480,
  S: 640,
  M: 800,
  L: 1024,
  XL: 1280,
  XLL: 1440,
}
