import { createGlobalStyle, css } from 'styled-components'
import { colors } from './theme'

const Global = css`
  html,
  body {
    padding: 0;
    margin: 0;
    background: #04020d;
    font-family: 'Tomorrow', sans-serif, 'DM_Mono';
    color: white;
  }

  html {
    scroll-behavior: smooth;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
  }

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #fff !important;
    }
  }

  *::-webkit-scrollbar {
    width: 12px;
    background-color: rgba(35, 147, 184, 0.01);
    border-radius: 4px;
  }
  *::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: ${colors.primary};
    border-radius: 20px;
    background-clip: content-box;
  }

  .hyper-link {
    position: relative;
    cursor: pointer;

    font-family: var(--font-dm-mono);
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -2px;
      left: 0;
      background-color: ${colors.primary};
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  .highlight {
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -2px;
      left: 0;
      background-color: ${colors.primary};
    }

    &:hover {
      filter: brightness(75%);
    }
  }

  :root {
    --toastify-color-light: rgb(0, 12, 28);
    --toastify-color-progress-light: ${colors.primary};
    --toastify-toast-width: auto;
    --wcm-z-index: 110 !important;
    --wcm-overlay-background-color: rgba(0, 0, 0, 0.8) !important;
  }

  li {
    font-family: var(--font-dm-mono);
    font-weight: 500;
  }
`

const BaseStyles = createGlobalStyle<{ isLanding?: boolean }>`
${Global};

html{
  overflow-y: ${({ isLanding }) => (isLanding ? 'hidden' : 'scroll')};
}
`

const GlobalStyles = ({ isLanding }: { isLanding?: boolean }) => (
  <BaseStyles isLanding={isLanding} />
)

export default GlobalStyles
