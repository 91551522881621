import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { StorageKey, ToastType } from 'shared/constants'
import { axiosInstance } from 'src/logic/axios/axiosInstance'
import { setShowRegisterModal, setShowWelcomeModal, showToast } from './uiSlice'

interface IAuthState {
  registerProgress: AUTH_MODAL_STATUS
  loading?: boolean
  error?: boolean
  token?: string | null
  resetingPassword: boolean
}

export enum AUTH_MODAL_STATUS {
  ENTER_EMAIL = 1,
  VERIFY_OTP = 2,
  CREATE_PASSWORD = 3,
  ENTER_PASSWORD = 4,
  RESET_PASSWORD = 5,
  UPDATE_PASSWORD = 6,
}

export const initialState: IAuthState = {
  registerProgress: AUTH_MODAL_STATUS.ENTER_EMAIL,
  resetingPassword: false
}

export const registerUser = createAsyncThunk<any, any>(
  'registerUser',
  async (args, { dispatch }) => {
    try {
      const { email } = args
      const response = await axiosInstance.post('user/onboarding', {
        email,
      })

      if (response.data) {
        return response.data?.data
      } else {
        return []
      }
    } catch (error) {
      // @ts-ignore
      dispatch(error?.response?.data?.message)
    }
  }
)

export const loginUser = createAsyncThunk<any, any>(
  'loginUser',
  async (args, { dispatch }) => {
    try {
      const { email, password } = args
      const response = await axiosInstance.post('user/login', {
        email,
        password,
      })
      if (response.data) {
        localStorage.setItem(StorageKey.token, response.data?.data?.token)
        dispatch(
          showToast({
            message: 'Login Successful',
            toastType: ToastType.success,
          })
        )
        return response.data?.data
      } else {
        return []
      }
    } catch (error: any) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          toastType: ToastType.error,
        })
      )
    }
  }
)

export const emailVerification = createAsyncThunk<any, any>(
  'emailVerification',
  async (args, { dispatch }) => {
    try {
      const { email, otp } = args
      const response = await axiosInstance.post('user/verification', {
        email,
        otp,
      })

      if (response.data) {
        //temporary token for adding password
        localStorage.setItem(StorageKey.token, response.data?.data?.token)
        return response.data
      } else {
        return []
      }
    } catch (error: any) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          toastType: ToastType.error,
        })
      )
    }
  }
)

export const createPassword = createAsyncThunk<any, any>(
  'createPassword',
  async (args, { dispatch }) => {
    const { email, password, resetingPassword } = args
    try {
      const response = await axiosInstance.post('user/add-password', {
        email,
        password,
      })

      if (response.data) {
        localStorage.setItem(StorageKey.token, response.data?.data?.token)
        dispatch(
          showToast({
            message: resetingPassword ? "Password Changed Successfully" : 'Registration successful.',
            toastType: ToastType.success,
          })
        )
        dispatch(setShowRegisterModal(false))
        dispatch(setShowWelcomeModal(true))
        return response.data?.data
      } else {
        return []
      }
    } catch (error: any) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          toastType: ToastType.error,
        })
      )
    }
  }
)

export const resetPassword = createAsyncThunk<any, any>(
  'resetPassword',
  async (args, { dispatch }) => {
    const { email } = args
    try {
      const response = await axiosInstance.post('user/reset-password', {
        email,
      })

      if (response.data) {
        localStorage.setItem(StorageKey.token, response.data?.data?.token)
        return response.data?.data
      } else {
        return []
      }
    } catch (error: any) {
      dispatch(showToast({
        message: error?.response?.data?.message,
        toastType: ToastType.error,
      }))
    }
  }
)

export const updatePassword = createAsyncThunk<any, any>(
  'updatePassword',
  async (args, { dispatch }) => {
    const { oldPassword, newPassword, resetForm } = args
    try {
      await axiosInstance.post('user/update-password', {
        oldPassword, newPassword
      })

      dispatch(
        showToast({
          message: 'Password Updated Successfully.',
          toastType: ToastType.success,
        })
      )
      resetForm()
      dispatch(setShowRegisterModal(false))
    } catch (error: any) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          toastType: ToastType.error,
        })
      )
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setRegisterProgress: (
      state,
      { payload }: { payload: AUTH_MODAL_STATUS }
    ) => {
      state.registerProgress = payload
    },
    setUserToken: (state, action) => {
      state.token = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // registerUser
      .addCase(registerUser.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.registerProgress = action.payload?.emailVerified ? AUTH_MODAL_STATUS.ENTER_PASSWORD : AUTH_MODAL_STATUS.VERIFY_OTP
        state.loading = false
        state.resetingPassword = false
      })
      .addCase(registerUser.rejected, (state) => {
        state.error = true
        state.loading = false
      })
      // loginUser
      .addCase(loginUser.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        if (action?.payload?.token) {
          state.token = action?.payload?.token
        }
        state.loading = false
        state.resetingPassword = false
      })
      .addCase(loginUser.rejected, (state) => {
        state.error = true
        state.loading = false
        // state.errorDetails = action.payload?.message
      })
      // emailVerification
      .addCase(emailVerification.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(emailVerification.fulfilled, (state, action) => {
        if (action?.payload?.message === 'otp verification successful') {
          state.registerProgress = AUTH_MODAL_STATUS.CREATE_PASSWORD
        }

        state.loading = false
      })
      .addCase(emailVerification.rejected, (state) => {
        state.error = true
        state.loading = false
      })
      // createPassword
      .addCase(createPassword.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(createPassword.fulfilled, (state, action) => {
        state.token = action.payload.token
        state.loading = false
      })
      .addCase(createPassword.rejected, (state) => {
        state.error = true
        state.loading = false
      })
      // resetPassword
      .addCase(resetPassword.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.registerProgress = AUTH_MODAL_STATUS.VERIFY_OTP
        state.loading = false
        state.resetingPassword = true
      })
      .addCase(resetPassword.rejected, (state) => {
        state.error = true
        state.loading = false
      })
  },
})

export const { setRegisterProgress, setUserToken } = authSlice.actions

export default authSlice.reducer
