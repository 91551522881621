import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
interface NetworkData {
  urls: string[]
  name: string
  nativeCurrency: string
  blockExplorerUrls: string[]
}

interface I_RpcArray {
  [key: number]: NetworkData
}

const Chains: I_RpcArray = {
  80001: {
    urls: ['https://rpc-mumbai.maticvigil.com'],
    name: 'Polygon Mumbai',
    nativeCurrency: 'MATIC',
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
}

const [mainnet, ...optionalChains] = Object.keys(Chains).map(Number)
export const [walletConnectV2, walletConnectHooks] =
  initializeConnector<WalletConnectV2>(
    (actions) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: '640f770570a3bcfff3db3e3baeb1fd2a',
          chains: [Number(mainnet)],
          showQrModal: true,
          relayUrl: 'wss://relay.walletconnect.com',
        },
      })
  )
