import { getConfig } from 'config'
import { createClient, cacheExchange, fetchExchange } from 'urql'

const APIURL = getConfig().subgraphUrl

interface TokenGraph {
  id: string;
  tokenId: string;
  timestamp: string;
  owner: string;
  mintedOrTransferred: string;
  value: string;
  __typename: string;
}

export interface TokenDataGraph {
  tokens: TokenGraph[];
}

export const tokensQuery = (owner: string) => ` 
  query {
    tokens(where: {owner: "${owner}"}) {
        id
        tokenId
        timestamp
        owner
        mintedOrTransferred
        value
      }
  }
`

interface UserTransfer {
  blockNumber: string;
  blockTimestamp: string;
  from: string;
  id: string;
  operator: string;
  to: string;
  tokenId: string;
  transactionHash: string;
  value: string;
  __typename: string;
}

export interface UserTransfersData {
  transfers: UserTransfer[];
}


export const transfersQuery = (owner: string) => ` 
    query{
        transfers(
            where: {or: [{from: "${owner}"}, {to: "${owner}"}]}
            orderBy: blockTimestamp
            orderDirection: desc
          ) {
            blockNumber
            blockTimestamp
            from
            id
            operator
            to
            tokenId
            transactionHash
            value
          }
    }
`


interface Transfer {
  id: string;
  operator: string;
  from: string;
  to: string;
  tokenId: string;
  value: string;
  blockNumber: string;
  blockTimestamp: string;
  transactionHash: string;
  __typename: string;
}

export interface TransfersResponse {
  transfers: Transfer[];
}


export const transfersByTokenId = (tokenId: string) => ` 
query {
  transfers(
    where: {tokenId: ${tokenId}},
    orderBy: blockTimestamp,
    orderDirection: desc
    ) {
    id
    operator
    from
    to
    tokenId
    value
    blockNumber
    blockTimestamp
    transactionHash
  }
}
`

export const client = createClient({
  url: APIURL,
  exchanges: [cacheExchange, fetchExchange],
})
