import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosInstance } from 'src/logic/axios/axiosInstance'

interface InitialState {
  loading?: boolean
  error?: boolean
  tokenPrices: any
  pricePerNftUsd: number
}

export const initialState: InitialState = {
  tokenPrices: {},
  pricePerNftUsd: 0,
}

export const getTokenPrices = createAsyncThunk('getTokenPrices', async () => {
  const cryptoSymbols = {
    matic: 'matic-network',
    ethereum: 'ethereum',
    usdt: 'tether',
    usdc: 'usd-coin',
  } // Cryptocurrency symbols

  const fiatSymbol = 'usd'

  const apiUrl = `https://api.coingecko.com/api/v3/simple/price?ids=${Object.values(
    cryptoSymbols
  ).join(',')}&vs_currencies=${fiatSymbol}`

  const approxCoinPrices: any = {
    ethereum: 2066.58,
    matic: 0.77748,
    usdt: 1,
    usdc: 1,
  }

  try {
    const response = await fetch(apiUrl)
    const data = await response.json()
    const fiatPrices: any = {}

    Object.entries(cryptoSymbols).map(([cryptoKey, cryptoVal]) => {
      if (data[cryptoVal]) {
        const cryptoPrice = data[cryptoVal][fiatSymbol]
        fiatPrices[cryptoKey] = cryptoPrice
      } else {
        fiatPrices[cryptoKey] = approxCoinPrices[cryptoKey]
      }
    })

    return fiatPrices
  } catch (error) {
    console.log('Error:', error)
    return approxCoinPrices
  }
})

export const getPricePerNftUsd = createAsyncThunk(
  'getPricePerNftUsd',
  async (_args, { dispatch }) => {
    try {
      const amountResponse = await axiosInstance.get('payment/nft-amount')
      const amount = amountResponse.data.data?.amount

      dispatch(setPricePerNftUsd(amount))
    } catch (error) {
      console.log({ error })
    }
  }
)

const tokenPricesSlice = createSlice({
  name: 'tokenPricesSlice',
  initialState: initialState,
  reducers: {
    setPricePerNftUsd: (state, action) => {
      state.pricePerNftUsd = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      //tokenPrices
      .addCase(getTokenPrices.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(getTokenPrices.fulfilled, (state, action) => {
        state.tokenPrices = action.payload
      })
      .addCase(getTokenPrices.rejected, (state) => {
        state.error = true
        state.loading = false
      })
  },
})

const { setPricePerNftUsd } = tokenPricesSlice.actions

export default tokenPricesSlice.reducer
