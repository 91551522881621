import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ToastType } from 'shared/constants'

interface IToastObj {
  message: string
  toastType: ToastType
}

export const initialState = {
  showConnectModal: false,
  showConnectWalletStatusModal: false,
  showRegisterModal: false,
  toastObj: {
    message: '',
    toastType: ToastType.error,
  },
  showWelcomeModal: false,
  showWrongNetworkModal: false,
  showSuccessModal: false,
  successMessage: '',
  musicOn: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    setShowConnectModal: (state, action) => {
      state.showConnectModal = action.payload
    },
    setShowConnectWalletStatusModal: (state, action) => {
      state.showConnectWalletStatusModal = action.payload
    },
    setShowRegisterModal: (state, action) => {
      state.showRegisterModal = action.payload
    },
    showToast: (state, action: PayloadAction<IToastObj>) => {
      state.toastObj = {
        message: action.payload.message,
        toastType: action.payload.toastType,
      }
    },
    setShowWelcomeModal: (state, action) => {
      state.showWelcomeModal = action.payload
    },
    setShowWrongNetworkModal: (state, action) => {
      state.showWrongNetworkModal = action.payload
    },
    setShowSuccessModal: (state, action) => {
      state.showSuccessModal = action.payload
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload
    },
    setMusicOn: (state, action) => {
      state.musicOn = action.payload
    },
  },
})

export const {
  setShowConnectModal,
  setShowRegisterModal,
  showToast,
  setShowWelcomeModal,
  setShowWrongNetworkModal,
  setShowSuccessModal,
  setShowConnectWalletStatusModal,
  setSuccessMessage,
  setMusicOn,
} = uiSlice.actions

export default uiSlice.reducer
