import { Tomorrow, DM_Mono } from 'next/font/google'
import { Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import GlobalStyles from '../styles/GlobalStyles'
import type { AppProps } from 'next/app'
import store from 'src/logic/redux/store'
import CustomToast from 'shared/components/toast'
import { connectors } from 'src/blockchain/connectors/connectors'
import { useEffect } from 'react'
import { eagerConnection } from 'src/blockchain/eagerConnection'

const tomorrow = Tomorrow({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
})

const dm_mono = DM_Mono({
  subsets: ['latin'],
  weight: ['400', '500'],
  variable: '--font-dm-mono',
})

export default function App({ Component, pageProps }: AppProps) {
  const pathName =
    typeof window !== 'undefined' ? window?.location?.pathname : ''

  useEffect(() => {
    eagerConnection()
  }, [])

  return (
    <Web3ReactProvider connectors={connectors}>
      <Provider store={store}>
        <GlobalStyles isLanding={pathName === '/'} />
        <style jsx global>{`
          :root {
            --font-tomorrow: ${tomorrow.style.fontFamily};
            --font-dm-mono: ${dm_mono.style.fontFamily};
          }
        `}</style>
        <Component {...pageProps} />
        <CustomToast />
      </Provider>
    </Web3ReactProvider>
  )
}
