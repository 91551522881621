export const ENVIRONMENT: string =
  process.env.NEXT_PUBLIC_ENVIRONMENT ?? 'development'

interface ConfigUrls {
  apiUrl: string
  socketUrl: string
  awsUrl: string
  polygonscanUrl: string
  pinataUrl: string
  openSeaUrl: string
}

interface BlockchainConfig {
  rpcUrl: string
  chainName: string
  currencyName: string
  currencySymbol: string
  chainId: number
  vtrContractAddress: string
  subgraphUrl: string
  usdcAddress: string
  usdtAddress: string
  wethAddress: string
  maticAddress: string
}

interface ConfigValues extends ConfigUrls, BlockchainConfig {}

interface Config {
  [config: string]: ConfigValues
}

export const CONFIG: Config = {
  development: {
    //config urls
    apiUrl: 'https://api-vtrc.dev.rapidinnovation.tech/',
    socketUrl: 'wss://api-vtrc.dev.rapidinnovation.tech/',
    awsUrl: '',
    rpcUrl: 'https://rpc-mumbai.maticvigil.com',
    chainName: 'Mumbai',
    currencyName: '',
    currencySymbol: 'MATIC',
    chainId: 80001,
    polygonscanUrl: 'https://mumbai.polygonscan.com/tx/',
    vtrContractAddress: '0xB42Ba4c5c30d5FE4F4bd085590c20668E6715083',
    subgraphUrl:
      'https://api.studio.thegraph.com/query/35173/vtr-connect-mumbai/v0.0.4',
    pinataUrl:
      'https://gateway.pinata.cloud/ipfs/Qmd6dkJRp2JiQ4ph2MgrgyH2QsJPpjgVH21SvAU3biGt6g/',
    openSeaUrl:
      'https://testnets.opensea.io/assets/mumbai/0xB42Ba4c5c30d5FE4F4bd085590c20668E6715083/',
    usdcAddress: '0xc45db3a44c287eeCD16dF124B075a6B26019ED76',
    usdtAddress: '0x115F04cD4ec81773D3AfD9255f62E43866Fd049C',
    wethAddress: '0x0Bc239f4651C2BcB46C3a0488034138dE447b627',
    maticAddress: '0x0000000000000000000000000000000000000000',
  },
  qa: {
    //config urls
    apiUrl: 'https://api-vtrc.qa.rapidinnovation.tech/',
    socketUrl: 'wss://api-vtrc.qa.rapidinnovation.tech/',
    awsUrl: '',
    rpcUrl: 'https://rpc-mumbai.maticvigil.com',
    chainName: 'Mumbai',
    currencyName: '',
    currencySymbol: 'MATIC',
    chainId: 80001,
    polygonscanUrl: 'https://mumbai.polygonscan.com/tx/',
    vtrContractAddress: '0x922bc6cC0f0F8E10816E232c430319BFBcAC1eaa',
    subgraphUrl:
      'https://api.studio.thegraph.com/query/35173/vtr-connect-mumbai/uat',
    pinataUrl:
      'https://gateway.pinata.cloud/ipfs/Qmd6dkJRp2JiQ4ph2MgrgyH2QsJPpjgVH21SvAU3biGt6g/',
    openSeaUrl:
      'https://testnets.opensea.io/assets/mumbai/0x3e5e7db2c38953d920ca22603926b6c8f7c32bb2/',
    usdcAddress: '0xc45db3a44c287eeCD16dF124B075a6B26019ED76',
    usdtAddress: '',
    wethAddress: '0x0Bc239f4651C2BcB46C3a0488034138dE447b627',
    maticAddress: '0x0000000000000000000000000000000000000000',
  },
  uat: {
    //config urls // uat is preprod
    apiUrl: 'https://api-vtrc.uat.rapidinnovation.tech/',
    socketUrl: 'wss://api-vtrc.uat.rapidinnovation.tech/',
    awsUrl: '',
    rpcUrl: 'https://polygon-rpc.com/',
    chainName: 'Polygon',
    currencyName: '',
    currencySymbol: 'MATIC',
    chainId: 137,
    polygonscanUrl: 'https://polygonscan.com/tx/',
    vtrContractAddress: '0x17134D2A8fADFB344A327466D3424E9Ec19126a2',
    subgraphUrl:
      'https://api.studio.thegraph.com/query/49280/vtr-test/pre-prod',
    pinataUrl:
      'https://gateway.pinata.cloud/ipfs/Qmd6dkJRp2JiQ4ph2MgrgyH2QsJPpjgVH21SvAU3biGt6g/',
    openSeaUrl:
      'https://opensea.io/assets/matic/0x17134D2A8fADFB344A327466D3424E9Ec19126a2/',
    usdcAddress: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    usdtAddress: '',
    wethAddress: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    maticAddress: '0x0000000000000000000000000000000000000000',
  },
  master: {
    //config urls
    apiUrl: 'https://api.nft.vtrconnect.com/',
    socketUrl: 'wss://api.nft.vtrconnect.com/',
    awsUrl: '',
    rpcUrl: 'https://polygon-rpc.com/',
    chainName: 'Polygon',
    currencyName: '',
    currencySymbol: 'MATIC',
    chainId: 137,
    polygonscanUrl: 'https://polygonscan.com/tx/',
    vtrContractAddress: '0xf9da6320cbE2EE488a49cB16501D306Cc3A67169',
    subgraphUrl:
      'https://api.studio.thegraph.com/query/49280/vtr-prod/version/latest',
    pinataUrl:
      'https://gateway.pinata.cloud/ipfs/Qmd6dkJRp2JiQ4ph2MgrgyH2QsJPpjgVH21SvAU3biGt6g/',
    openSeaUrl:
      'https://opensea.io/assets/matic/0xf9da6320cbE2EE488a49cB16501D306Cc3A67169/',
    usdcAddress: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    usdtAddress: '',
    wethAddress: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    maticAddress: '0x0000000000000000000000000000000000000000',
  },
}

export const getConfig = (): ConfigValues => {
  return CONFIG[ENVIRONMENT]
}
