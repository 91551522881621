import PasswordHideIcon from 'public/assets/landing/passwordHide.svg'
import PasswordShowIcon from 'public/assets/landing/passwordShow.svg'
import Chevron from 'public/assets/gallery/chevdown.svg'
import DarkChevron from 'public/assets/gallery/darkChevron.svg'
import WelcomeSvg from 'public/assets/landing/welcome.svg'
import LargeLogo from 'public/assets/landing/largeLogo.svg'
import SettingsSvg from 'public/assets/gallery/settings.svg'
import CloseSvg from 'public/assets/landing/modalClose.svg'
import ErrorIcon from 'public/assets/gallery/error.svg'
import SuccessIcon from 'public/assets/profile/payment-success.svg'
import TickSvg from 'public/assets/gallery/tick.svg'
import ProfileImg from 'public/assets/profile/profile.png'

import FacebookIcon from 'public/assets/footer/facebook.svg'
import InstagramIcon from 'public/assets/footer/instagram.svg'
import LinkedinIcon from 'public/assets/footer/linkedin.svg'
import TwitterIcon from 'public/assets/footer/twitter.svg'
import YoutubeIcon from 'public/assets/footer/youtube.svg'
import DiscordIcon from 'public/assets/footer/discord.svg'

export {
  PasswordHideIcon,
  PasswordShowIcon,
  ErrorIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TickSvg,
  ProfileImg,
  SuccessIcon,
  TwitterIcon,
  YoutubeIcon,
  DiscordIcon,
  Chevron,
  WelcomeSvg,
  DarkChevron,
  LargeLogo,
  SettingsSvg,
  CloseSvg,
}
