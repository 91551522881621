import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { MetaMask } from '@web3-react/metamask'
import { metamask, metamaskHooks } from './metamask'
import { walletConnectHooks, walletConnectV2 } from './walletConnect'
import { Web3ReactHooks } from '@web3-react/core'

export const connectors: [MetaMask | WalletConnectV2, Web3ReactHooks][] = [
  [metamask, metamaskHooks],
  [walletConnectV2, walletConnectHooks],
]

export const connectorsObject = {
  metamask: {
    connector: metamask,
    hooks: metamaskHooks,
  },
  walletConnect: {
    connector: walletConnectV2,
    hooks: walletConnectHooks,
  },
}
