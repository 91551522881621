import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  MaticAddress,
  VTRCAddress,
  contract,
  contractToken,
  getAddress,
} from 'src/blockchain/contract'
import { axiosInstance } from 'src/logic/axios/axiosInstance'
import { ToastType } from 'shared/constants'
import { showToast } from './uiSlice'
import { removeTransferedNFTs } from './userSlice'
import { BigNumber, ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'

type PaymentMethod = 'fiat' | 'crypto' | ''
export type CryptoMethod = 'MATIC' | 'WETH' | 'USDT' | 'USDC'
type TransactionStatus =
  | 'SUCCESS'
  | 'ERROR'
  | 'HASH'
  | 'NOT_STARTED'
  | 'STARTED'

export enum NftStatusEnum {
  Available = 1,
  Sold = 2,
}

export type NFTListState = {
  nfts: any[]
  loading: boolean
  error: boolean | undefined
  cryptoMethod: CryptoMethod
  paymentMethod: PaymentMethod
  selectedNFTs: any[]
  transactionStatus: TransactionStatus
  transactionHash: string
  transactionMessage: string
  fiatLoading?: boolean
  page: number
  nftStatus: NftStatusEnum
}

const initialState: NFTListState = {
  nfts: [],
  loading: true,
  error: undefined,
  cryptoMethod: 'MATIC',
  paymentMethod: '',
  selectedNFTs: [],
  transactionStatus: 'NOT_STARTED',
  transactionHash: '',
  fiatLoading: false,
  transactionMessage: '',
  page: 1,
  nftStatus: NftStatusEnum.Available,
}

export type ArributesType = {
  traitType: string
  value: string[]
}

type NFTListArgs = {
  page: number
  limit: number
  search?: string
  attributes?: ArributesType[]
  nftStatus: NftStatusEnum
}

export const getNFTs = createAsyncThunk<any, NFTListArgs>(
  'getNFTs',
  async (args) => {
    const { limit, page, attributes, search, nftStatus } = args
    try {
      const response = await axiosInstance.post('nft/list', {
        limit,
        page,
        attributes,
        search,
        nftStatus,
      })

      if (response.data) {
        return response.data?.data || []
      } else {
        return []
      }
    } catch (error) {
      console.log({ error })
      return []
    }
  }
)

export const TransactionEnum = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  HASH: 'HASH',
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
}

export const lockNFTs = async (
  nftIds: string[],
  dispatch: any
): Promise<any> => {
  try {
    await axiosInstance.post('nft/lock-nft', {
      nftIds,
    })
    return true
  } catch (error) {
    dispatch(setTransactionStatus(TransactionEnum.ERROR))
    dispatch(
      setTransactionMessage(
        'Selected NFTs are currently locked, please try after some time'
      )
    )
    return false
  }
}

export const unlockNFTs = (nftIds: string[]) => {
  axiosInstance
    .post('nft/unlock-nft', {
      nftIds,
    })
    .catch((error) => console.log(error))
}

const getAllowance = async (
  crypto: any,
  account: any,
  priceInToken: any,
  dispatch: any,
  callApprove: boolean,
  nftIds: string[],
  signer: any
): Promise<any> => {
  const tokenContract = contractToken(crypto, signer)

  // @ts-ignore
  const balance = BigNumber.from(await tokenContract.balanceOf(account))

  // console.log('balance.lt(priceInToken)=====>', balance.lt(priceInToken))
  if (balance.lt(priceInToken)) {
    dispatch(setTransactionStatus(TransactionEnum.ERROR))
    dispatch(setTransactionMessage('Insufficient balance'))
    unlockNFTs(nftIds)
    return false
  }
  // @ts-ignore
  const allowance = BigNumber.from(
    await tokenContract.allowance(account, VTRCAddress)
  )
  // console.log('allowance.lt(priceInToken)=====>', allowance.lt(priceInToken))
  if (allowance.lt(priceInToken)) {
    if (callApprove) {
      await tokenContract
        .approve(
          VTRCAddress,
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          { from: account }
        )
        .catch((error: any) => {
          console.log(error, 'error while minting nft')
          dispatch(setTransactionMessage('Transaction Declined'))
          dispatch(setTransactionStatus(TransactionEnum.ERROR))
          unlockNFTs(nftIds)
        })
    }
  } else {
    return true
  }
  return false
}

export const mintNFTS = async (args: any) => {
  const { account, crypto, dispatch, tokenIds, nftIds, signer, provider } = args

  const locked = await lockNFTs(nftIds, dispatch)
  if (!locked) return

  try {
    const PRICE_PER_NFT = BigNumber.from(await contract(provider).nftPriceUSD())

    const totalPriceInUSD = BigNumber.from(PRICE_PER_NFT).mul(
      BigNumber.from(tokenIds.length)
    )
    // console.log({ tokenIds })
    // console.log({ totalPriceInUSD })
    // console.log({ crypto })
    const paymentToken = getAddress(crypto)
    console.log({ account, paymentToken })

    let priceInToken = BigNumber.from(
      await contract(provider).convertToToken(paymentToken, totalPriceInUSD)
    )
    // console.log({ priceInToken })

    if (paymentToken !== MaticAddress) {
      const check1 = await getAllowance(
        crypto,
        account,
        priceInToken,
        dispatch,
        true,
        nftIds,
        signer
      )
      if (!check1) {
        const check2 = await getAllowance(
          crypto,
          account,
          priceInToken,
          dispatch,
          false,
          nftIds,
          signer
        )
        if (!check2) {
          dispatch(setTransactionStatus(TransactionEnum.ERROR))
          dispatch(setTransactionMessage('Insufficient Allowance'))
          unlockNFTs(nftIds)
          return
        }
      }
    } else {
      // @ts-ignore
      const balance = await provider.getBalance(account)
      // console.log({ balance })
      const balanceInEth = Number(formatEther(balance))

      const priceInTokenInEth = formatEther(priceInToken)

      const priceInTokenWithSlippage = Number(Number(priceInTokenInEth) * 1.05) // for slippage

      // console.log({ balanceInEth, priceInToken })

      if (balanceInEth < priceInTokenWithSlippage) {
        dispatch(setTransactionStatus(TransactionEnum.ERROR))
        dispatch(setTransactionMessage('Insufficient balance'))
        unlockNFTs(nftIds)
        return
      }
    }

    const priceInTokenInEth = formatEther(priceInToken)

    const priceInTokenWithSlippage = Number(Number(priceInTokenInEth) * 1.05)
    const priceInTokenWithSlippageInWei = ethers.utils.parseEther(
      priceInTokenWithSlippage.toString()
    )

    const result = await contract(signer)
      .buyNFTs(tokenIds, paymentToken, {
        from: account,
        value:
          paymentToken === MaticAddress ? priceInTokenWithSlippageInWei : 0,
      })
      .catch((error: any) => {
        console.log(error, 'error while minting nft')
        dispatch(setTransactionMessage('Transaction Declined'))
        dispatch(setTransactionStatus(TransactionEnum.ERROR))
        unlockNFTs(nftIds)
      })

    if (result?.hash) {
      // console.log(result?.hash)
      dispatch(setTransactionHash(result?.hash))
      dispatch(setTransactionStatus(TransactionEnum.HASH))
    }

    const receipt = await result.wait()

    if (receipt.status === 1) {
      // console.log(receipt)
      dispatch(setTransactionStatus(TransactionEnum.SUCCESS))
      dispatch(removeMintedNFTs({ nftIds }))
    }
  } catch (error) {
    console.log({ error })
  }
}

export const mintNFTSFiat = createAsyncThunk<any, any>(
  'mintNFTSFiat',
  async (args, { dispatch }) => {
    try {
      const { nftIds } = args
      const amountResponse = await axiosInstance.get('payment/nft-amount')
      const amount = amountResponse.data.data?.amount
      const response = await axiosInstance.post('payment/initiate-payment', {
        nftIds,
        amount: amount * nftIds?.length,
      })

      if (response.data) {
        window.open(response.data.data.redirectUrl)
        return response.data
      } else {
        return []
      }
    } catch (error: any) {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          toastType: ToastType.error,
        })
      )

      dispatch(setTransactionStatus(TransactionEnum.ERROR))
      dispatch(setTransactionMessage(error?.response?.data?.message))

      return
    }
  }
)

type TransferNFTArgs = {
  account: string
  toAddress: string
  tokenId: string
  dispatch: any
  isEmailAddress: boolean
  signer?: string
}

export const transferNFT = async (args: TransferNFTArgs) => {
  const { account, tokenId, dispatch, isEmailAddress, signer } = args
  let { toAddress } = args
  // console.log({ account, toAddress, tokenId })

  if (isEmailAddress) {
    dispatch(setTransactionMessage('Fetching Wallet Address'))

    //get associated wallet address here
    try {
      const response = await axiosInstance.post('nft/transfer', {
        address: toAddress,
        tokenId,
      })

      toAddress = response?.data?.data?.walletAddress
    } catch (error: any) {
      dispatch(setTransactionMessage(error?.response?.data?.message))
      dispatch(setTransactionStatus(TransactionEnum.ERROR))
      return
    }
  }
  dispatch(setTransactionMessage('Waiting for Confirmation'))

  try {
    const result = await contract(signer)
      .safeTransferFrom(account, toAddress, tokenId, 1, '0x')
      .catch((error: any) => {
        console.log(error, 'error while minting nft')
        dispatch(setTransactionMessage('Transaction Declined'))
        dispatch(setTransactionStatus(TransactionEnum.ERROR))
      })

    if (result?.hash) {
      // console.log(result?.hash)
      dispatch(setTransactionHash(result?.hash))
      dispatch(setTransactionStatus(TransactionEnum.HASH))
    }

    const receipt = await result.wait()

    if (receipt.status === 1) {
      // console.log(receipt)
      dispatch(setTransactionStatus(TransactionEnum.SUCCESS))
      dispatch(removeTransferedNFTs({ tokenId }))
    }
  } catch (error) {
    console.log('Transfer error=====>', error)

    // dispatch(setTransactionMessage('Transaction Declined'))
    // dispatch(setTransactionStatus(TransactionEnum.ERROR))
  }
}

export const transferNFTCustodial = createAsyncThunk<any, any>(
  'transferNFTCustodial',
  async (args, { dispatch }) => {
    const {
      address,
      tokenId,
      setTranscProgressCustodial,
      setShowTransferModal,
    } = args
    dispatch(setTransactionMessage('Waiting for Confirmation'))
    setTranscProgressCustodial(false)
    setShowTransferModal(false)
    try {
      const response = await axiosInstance.post('nft/transfer', {
        address,
        tokenId,
      })
      return response.data
    } catch (error: any) {
      setTranscProgressCustodial(false)
      setShowTransferModal(false)
      dispatch(setTransactionMessage(error?.response?.data?.message))
      dispatch(setTransactionStatus(TransactionEnum.ERROR))
    }
  }
)

const nftsSlice = createSlice({
  name: 'nftsList',
  initialState: initialState,
  reducers: {
    clearNFTs: (state) => {
      state.nfts = []
    },
    setCryptoMethod: (state, action) => {
      state.cryptoMethod = action.payload
    },
    setPaymentMethod: (state, { payload }: { payload: PaymentMethod }) => {
      state.paymentMethod = payload
    },
    setSelectedNFTs: (state, action) => {
      state.selectedNFTs = action.payload
    },
    setTransactionStatus: (state, action) => {
      state.transactionStatus = action.payload
    },
    setTransactionHash: (state, action) => {
      state.transactionHash = action.payload
    },
    setTransactionMessage: (state, action) => {
      state.transactionMessage = action.payload
    },
    removeMintedNFTs: (state, action) => {
      const { nftIds } = action.payload
      state.nfts = state.nfts.filter((nft: any) => !nftIds.includes(nft._id))
      state.selectedNFTs = []
    },
    setPage: (state, action) => {
      const page = action.payload
      state.page = page
      if (page === 1) {
        state.nfts = []
      }
    },
    setNftStatus: (state, action) => {
      state.nftStatus = action.payload
      state.page = 1
      state.nfts = []
    },
  },
  extraReducers: (builder) => {
    builder
      //getNFTs
      .addCase(getNFTs.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(getNFTs.fulfilled, (state, action) => {
        const { data } = action.payload
        state.nfts = [...state.nfts, ...data]
        state.loading = false
      })
      .addCase(getNFTs.rejected, (state) => {
        state.error = true
        state.loading = false
      })
      //mintNFTsFiat
      .addCase(mintNFTSFiat.pending, (state) => {
        state.fiatLoading = true
        state.error = undefined
      })
      .addCase(mintNFTSFiat.fulfilled, (state, action) => {
        state.fiatLoading = false
      })
      .addCase(mintNFTSFiat.rejected, (state) => {
        state.error = true
        state.fiatLoading = false
      })
      //transferNFTCustodial
      .addCase(transferNFTCustodial.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(transferNFTCustodial.fulfilled, (state, action) => {
        const { nftId } = action.meta.arg
        state.loading = false
        state.nfts = state.nfts.filter((nft: any) => nft.nftId !== nftId)
      })
      .addCase(transferNFTCustodial.rejected, (state) => {
        state.error = true
        state.loading = false
      })
  },
})

export const {
  setCryptoMethod,
  setPaymentMethod,
  setSelectedNFTs,
  setTransactionStatus,
  setTransactionHash,
  setTransactionMessage,
  removeMintedNFTs,
  setPage,
  setNftStatus,
} = nftsSlice.actions

export default nftsSlice.reducer
