import styled, { keyframes } from 'styled-components'
import { colors, screenSizes } from './theme'

interface IFlexRow {
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  padding?: string
  margin?: string
  gap?: string
  flexWrap?: string
  whiteSpace?: string
  overflowX?: string
  width?: string
}

export const FlexRow = styled.div<IFlexRow>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : ''};
  margin: ${(props) => (props.margin ? props.margin : '')};
  padding: ${(props) => (props.padding ? props.padding : '')};
  gap: ${(props) => (props.gap ? props.gap : '10px')};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : '')};
  white-space: ${(props) => props.whiteSpace};
  overflow-x: ${(props) => props.overflowX};
  width: ${(props) => (props.width ? props.width : '100%')} !important;
`

interface IFlexCol {
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  padding?: string
  margin?: string
  gap?: string
  flexWrap?: string
  whiteSpace?: string
  overflowX?: string
  flex?: string
}

export const FlexCol = styled.div<IFlexCol>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : 'flex-start'};
  ${(props) => props.flex && `flex: ${props.flex};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
    padding: ${(props) => (props.padding ? props.padding : '')};
  gap: ${(props) => (props.gap ? props.gap : ' ')};
  flex: ${(props) => props.flex};
`

export const gradientAnimation = keyframes`
  to {
    background-position:top left;
  }

`

export const ErrorMsg = styled.p`
  width: 100%;
  color: ${colors.error};
`

export const Divider = styled.div<{ margin?: string; bg?: string }>`
  height: 1px;
  background: ${(props) => (props.bg ? props.bg : colors.borderColor)};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  position: relative;
`
export const DividerText = styled.p`
  background-color: ${colors.modalBackground};
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  font-family: var(--font-tomorrow);
  font-weight: 400;

  @media (min-width: ${screenSizes.XS}px) {
    font-size: 14px;
  }
`

export const SectionContainer = styled.section`
  padding: 20px;
  position: relative;
  background-image: linear-gradient(
      to right,
      rgba(35, 147, 184, 0.1) 1px,
      transparent 1px
    ),
    linear-gradient(rgba(35, 147, 184, 0.1) 1px, transparent 1px);
  background-size: 40px 40px;

  @media (min-width: ${screenSizes.L}px) {
    padding: 80px 30px 320px 30px;
  }

  @media (min-width: ${screenSizes.M}px) {
    padding: 80px 72px 320px 72px;
  }

  .self-center {
    align-self: center;
    font-size: 16px;
    font-family: var(--font-dm-mono);
    font-weight: 400;
  }

  &::before {
    content: '';
    position: absolute;
    width: 20%;
    height: 10%;
    z-index: 1;
    box-shadow: rgb(35, 147, 184) 0px 0px 140px 300px;
    background: rgb(35, 147, 184);
    top: 1150px;
    left: -10%;
    transform: translateX(150%);
    opacity: 0.1;
    border-radius: 100%;
  }

  &.gallery {
    padding-top: 0;
    min-height: 100vh;
  }

  &.edit {
    &::before {
      content: '';
      position: absolute;
      width: 20%;
      height: 10%;
      z-index: 1;
      box-shadow: rgba(35, 147, 184, 0.3) 0px 0px 140px 540px;
      background: rgba(35, 147, 184, 0.3);
      top: 440px;
      left: 30%;
      transform: translateX(150%);
      opacity: 0.1;
      border-radius: 100%;
    }
  }

  &.provenance {
    overflow-x: hidden;
  }
  &.profile {
    &::before {
      top: 420px;
      left: 20%;
      transform: translateX(150%);
      opacity: 0.1;
      border-radius: 100%;
      box-shadow: rgba(35, 147, 184, 0.3) 0px 0px 140px 540px;
      background: rgba(35, 147, 184, 0.3);
    }
  }

  &.privacy {
    @media (min-width: ${screenSizes.L}px) {
      padding: 80px 30px 156px 30px;
    }

    @media (min-width: ${screenSizes.M}px) {
      padding: 80px 72px 156px 72px;
    }
  }

  &#Roadmap {
    @media (min-width: ${screenSizes.L}px) {
      padding: 91px 30px 244px 30px;
    }

    @media (min-width: ${screenSizes.M}px) {
      padding: 91px 72px 244px 72px;
    }
  }
`
