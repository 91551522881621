import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getConfig } from 'config'
import { io } from 'socket.io-client'
import {
  TransactionEnum,
  removeMintedNFTs,
  setTransactionHash,
  setTransactionStatus,
} from './nftsSlice'
import { WalletTypeEnum, removeTransferedNFTs } from './userSlice'
import { showToast } from './uiSlice'
import { ToastType } from 'shared/constants'

interface IToastObj {
  socketConnected: boolean
}

const initialState: IToastObj = {
  socketConnected: false,
}

// export const socket = io(getConfig().socketUrl)

export const connectSocket = createAsyncThunk<any, any>(
  'socket/connect',
  async (args, { dispatch, getState }) => {
    const socket = io(getConfig().socketUrl)
    socket.connect()

    const { userId } = args
    console.log('connecting socket', userId)

    // Received event 'transfer-64757c4bd52b755af4e4133b': {userId: '64757c4bd52b755af4e4133b', transactionHash: '0x61f8b84d983f5a96f79da4f7ca06043d84cf313d32921414e87ab4ceb1b57cae'}transactionHash: "0x61f8b84d983f5a96f79da4f7ca06043d84cf313d32921414e87ab4ceb1b57cae"userId: "64757c4bd52b755af4e4133b"[[Prototype]]: Object
    // Received event 'transaction_completed': {userId: '64757c4bd52b755af4e4133b', transactionHash: '0x61f8b84d983f5a96f79da4f7ca06043d84cf313d32921414e87ab4ceb1b57cae'}

    try {
      socket.on('connect', () => {
        // Replace event name with connection event name
        console.log('websocket connected')
        dispatch(setSocketConnected(true))
        // Emit the 'registerWalletAddress' event
        socket.emit('registerUser', {
          userId,
        })
      })

      socket.on('connect_error', (w) => {
        // Replace event name with connection event name
        console.log('websocket connect_error', w)
      })
      socket.on('error', (w) => {
        // Replace event name with connection event name
        console.log('websocket error', w)
      })

      socket.onAny((event, payload) => {
        console.log(`Received event '${event}':`, payload)

        //@ts-ignore
        const user = getState()?.userReducer?.user

        console.log({ user, userId })

        if (payload?.userId == userId) {
          //&& user?.walletType === WalletTypeEnum.CUSTODIAL_WALLET
          if (event === `transfer-${userId}`) {
            dispatch(setTransactionHash(payload?.transactionHash))
            dispatch(setTransactionStatus(TransactionEnum.HASH))
          }
          if (
            event === 'transaction_completed' &&
            payload?.action === 'sender'
          ) {
            dispatch(setTransactionStatus(TransactionEnum.SUCCESS))
            dispatch(removeTransferedNFTs({ tokenId: payload?.tokenId }))
          }
          if (
            event === 'transaction_completed' &&
            payload?.action === 'receiver'
          ) {
            if (payload?.type === 'mint') {
              dispatch(setTransactionStatus(TransactionEnum.SUCCESS))
              dispatch(removeMintedNFTs({ nftIds: [payload?.tokenId] }))
            } else {
              dispatch(
                showToast({
                  message: 'NFT Received',
                  toastType: ToastType.success,
                })
              )
            }
          }
        }
      })

      socket.on('disconnect', () => {
        dispatch(setSocketConnected(false))
      })

      socket.on('reconnect', () => {})
    } catch (err) {
      console.log('err', err)
    }
  }
)

const socketSlice = createSlice({
  name: 'socketSlice',
  initialState: initialState,
  reducers: {
    setSocketConnected: (state, action) => {
      state.socketConnected = action.payload
    },
  },
})

export const { setSocketConnected } = socketSlice.actions

export default socketSlice.reducer
