import Image from 'next/image'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

import { colors, screenSizes } from 'styles/theme'

export const CloseIcon = styled(Image)`
  cursor: pointer;
`

export const ToastCont = styled(ToastContainer)`
  .toast-body {
    border: 1px solid ${colors.borderColor};
    border-radius: 0;
    max-width: 90%;
    margin: 0 auto;
    &::before {
      content: '';
      height: 30px;
      width: 70px;
      left: 0px;
      top: 0px;
      border-radius: 0px;
      position: absolute;
      border: 1px solid #2393b8;
      border-right: none;
      border-bottom: none;
    }

    p {
      font-family: var(--font-tomorrow);
      line-height: 17px;
      font-size: 16px;
      color: ${colors.white};
    }
    border-right: 4px solid ${colors.primary};

    @media (min-width: ${screenSizes.XS}px) {
      max-width: 730px;
    }
  }
`

export const NotificationCont = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;

  img {
    height: 24px;
    width: 24px;
  }
`
