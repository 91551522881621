import axios from "axios"
import { getConfig } from "config";

const baseUrl = "https://api-polygon.reservoir.tools"
const contractAddress = getConfig().vtrContractAddress

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        Accept: "*/*",
        "x-api-key": process?.env?.NEXT_PUBLIC_RESERVOIR || "demo-api-key"
    }
})

interface Token {
    token: {
        contract: string;
        tokenId: string;
        kind: string;
        name: string | null;
        image: string | null;
        imageSmall: string | null;
        imageLarge: string | null;
        rarityScore: number | null;
        rarityRank: number | null;
        supply: string;
        remainingSupply: string;
        media: string | null;
        isFlagged: boolean;
        lastFlagUpdate: string | null;
        lastFlagChange: string | null;
        collection: {
            id: string;
            name: string;
            imageUrl: string | null;
            openseaVerificationStatus: string;
            floorAskPrice: string | null;
            royaltiesBps: number;
            royalties: any[]; // You might want to define the type for this array if needed
        };
        lastAppraisalValue: string | null;
    };
    ownership: {
        tokenCount: string;
        onSaleCount: string;
        floorAsk: {
            id: string | null;
            price: string | null;
            maker: string | null;
            kind: string | null;
            validFrom: string | null;
            validUntil: string | null;
            source: Record<string, any>; // You might want to define the type for this object if needed
        };
        acquiredAt: string;
    };
}

interface TokenResponse {
    tokens: Token[];
    continuation: null | string; // You might want to define the exact type for continuation if needed
}

export const getUserTokensReservoir = async (walletAddress: string) => {
    try {
        const response = await axiosInstance.get<TokenResponse>(`users/${walletAddress}/tokens/v7`, {
            params: {
                contract: contractAddress
            }
        })

        return response.data?.tokens
    } catch (error) {
        return []
    }
}

interface Activity {
    type: string;
    fromAddress: string;
    toAddress: string;
    price: {
        currency: {
            contract: string;
            name: string;
            symbol: string;
            decimals: number;
        };
        amount: {
            raw: string;
            decimal: number;
            usd: number;
            native: number;
        };
    };
    amount: number;
    timestamp: number;
    createdAt: string;
    contract: string;
    token: {
        tokenId: string;
        tokenName: string | null;
        tokenImage: string | null;
    };
    collection: {
        collectionId: string;
        collectionName: string;
    };
    txHash: string;
    logIndex: number;
    batchIndex: number;
}

interface ActivitiesResponse {
    activities: Activity[];
    continuation: null | any; // Update this type if you know the specific structure
}

export const getTokenActivityReservoir = async (tokenId: string) => {
    try {
        const response = await axiosInstance.get<ActivitiesResponse>(`tokens/${contractAddress}:${tokenId}/activity/v5`)
        return response.data?.activities
    } catch (error) {
        return []
    }
}

interface Currency {
    contract: string;
    name: string;
    symbol: string;
    decimals: number;
}

interface Amount {
    raw: string;
    decimal: number;
    usd: number;
    native: number;
}

interface Price {
    currency: Currency;
    amount: Amount;
}

interface TokenInfo {
    tokenId: string;
    tokenName: string | null;
    tokenImage: string | null;
    tokenMedia: string | null;
    tokenRarityRank: string | null;
    tokenRarityScore: string | null;
}

interface Collection {
    collectionId: string;
    collectionName: string;
}

interface UserActivity {
    type: string;
    fromAddress: string;
    toAddress: string;
    amount: number;
    timestamp: number;
    createdAt: string;
    contract: string;
    price?: Price;
    token: TokenInfo;
    collection: Collection;
    txHash: string;
    logIndex: number;
    batchIndex: number;
}

interface UserActivitiesResponse {
    activities: UserActivity[];
    continuation: null | any; // Replace 'any' with actual type if known
}

export const getUserActivityReservoir = async (walletAddress: string) => {
    try {
        const response = await axiosInstance.get<UserActivitiesResponse>(`users/activity/v6`, {
            params: {
                users: walletAddress,
                collection: contractAddress
            }
        })
        return response.data?.activities
    } catch (error) {
        return []
    }
}