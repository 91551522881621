import { CryptoMethod } from 'src/logic/redux/slices/nftsSlice'
import abi from './abi'
import abiToken from './abiToken'
import { getConfig } from 'config'
import { ethers } from 'ethers'

export const VTRCAddress = getConfig().vtrContractAddress
export const USDCAddress = getConfig().usdcAddress
export const USDTAddress = getConfig().usdtAddress
export const WETHAddress = getConfig().wethAddress
export const MaticAddress = getConfig().maticAddress

export const contract = (signer?: any) => {
  // @ts-ignore
  return new ethers.Contract(VTRCAddress, abi, signer)
}

export const getAddress = (crypto: CryptoMethod) => {
  if (crypto === 'WETH') {
    return WETHAddress
  } else if (crypto === 'USDT') {
    return USDTAddress
  } else if (crypto === 'USDC') {
    return USDCAddress
  } else {
    return MaticAddress
  }
}

export const contractToken = (crypto: CryptoMethod, provider: any) => {
  // @ts-ignore
  return new ethers.Contract(getAddress(crypto), abiToken, provider)
}
