import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { StorageKey, ToastType } from 'shared/constants'
import { axiosInstance } from 'src/logic/axios/axiosInstance'
import {
  client,
  tokensQuery,
  transfersQuery,
  transfersByTokenId,
  TokenDataGraph,
  TransfersResponse,
  UserTransfersData,
} from 'src/modules/profile/graph'
import { showToast } from './uiSlice'
import { setUserToken } from './authSlice'
import { IActivityData } from 'src/modules/profile/components/ActivitySection'
import { setPaymentMethod } from './nftsSlice'
import { useConnectWallet } from 'src/blockchain/hooks/useConnectWallet'
import {
  getTokenActivityReservoir,
  getUserActivityReservoir,
  getUserTokensReservoir,
} from 'src/modules/profile/reservoir'

interface UserState {
  user: {
    email: string
    walletAddress: string
    walletType?: WalletTypeEnum
    _id?: string
    firstName?: string
    lastName?: string
    profilePic?: string
    subscriptionStatus?: { status: string; subscriptionDate: any }
  }
  loading?: boolean
  error?: boolean
  userNFTs: any[]
  walletVerificationStatus: WalletVerificationStatus
  walletVerificationMessage: string
  userActivity: any[]
  nftDetailById: Partial<INftData>[]
  activityLoading?: boolean
  nftActivityById?: Partial<IActivityData>
  profileUpdateMessage?: string
}

interface IAttributesProps {
  traitType: string
  value: string
}

interface INftData {
  _id: string
  name: string
  description: string
  image: string
  attributes: IAttributesProps[]
  status: number
  tokenId: string
  createdAt: string
  updatedAt: string
}

type WalletVerificationStatus =
  | 'SUCCESS'
  | 'ERROR'
  | 'HASH'
  | 'NOT_STARTED'
  | 'STARTED'

export enum WalletTypeEnum {
  METAMASK = 1,
  WALLET_CONNECT = 2,
  CUSTODIAL_WALLET = 3,
}

export const initialState: UserState = {
  user: {
    walletAddress: '',
    email: '',
  },
  userNFTs: [],
  userActivity: [],
  walletVerificationStatus: 'NOT_STARTED',
  walletVerificationMessage: '',
  nftDetailById: [],
}

export const registerUser = createAsyncThunk<any, any>(
  'registerUser',
  async (args, { dispatch }) => {
    try {
      const { email } = args
      const response = await axiosInstance.post('user/onboarding', {
        email,
      })

      if (response.data) {
        return response.data?.data
      } else {
        return []
      }
    } catch (error) {
      // @ts-ignore
      dispatch(error?.response?.data?.message)
    }
  }
)

export const getUserDetails = createAsyncThunk<any>(
  'getUserDetails',
  async (_, { dispatch }) => {
    try {
      const response = await axiosInstance.get('user/user-details')

      if (response.data) {
        if (
          response.data?.data?.walletType === WalletTypeEnum.CUSTODIAL_WALLET
        ) {
          dispatch(setPaymentMethod('fiat'))
        }
        return response.data?.data
      } else {
        return []
      }
    } catch (error: any) {
      console.log({ error })
      dispatch(setUserToken(null))
      dispatch(
        showToast({
          message: 'Session Expired',
          toastType: ToastType.error,
        })
      )
      if (localStorage.getItem(StorageKey.token)) {
        localStorage.clear()
        const { deactivate } = useConnectWallet()
        deactivate()
        window.location.reload()
      }
    }
  }
)

interface ConnectWalletArgs {
  address: string
  walletType: WalletTypeEnum
  signatureHash: string
  signatureMessage: string
}

const connectWallet = createAsyncThunk<any, ConnectWalletArgs>(
  'connectWallet',
  async (args, { dispatch }) => {
    const { address, walletType, signatureHash, signatureMessage } = args

    try {
      const response = await axiosInstance.post('user/add-wallet', {
        walletAddress: address,
        walletType,
        signatureHash: signatureHash,
        signatureMessage,
      })

      if (response.data) {
        dispatch(setUserWalletAddress(address))
        dispatch(setWalletVerificationStatus('SUCCESS'))
        dispatch(setWalletVerificationMessage('Wallet Connected Successfully'))

        return response.data
      } else {
        return []
      }
    } catch (error: any) {
      dispatch(setWalletVerificationStatus('ERROR'))
      dispatch(setWalletVerificationMessage(error?.response?.data?.message))
      localStorage.removeItem('walletconnect')
    }
  }
)

export const createWallet = createAsyncThunk<any>(
  'createWallet',
  async (_args, { dispatch }) => {
    try {
      const response = await axiosInstance.post('user/create-wallet')

      if (response.data) {
        dispatch(setUserWalletAddress(''))
        dispatch(setWalletVerificationStatus('SUCCESS'))
        dispatch(setWalletVerificationMessage('Wallet Connected Successfully'))
        dispatch(setUserWalletAddress(response.data?.data?.walletAddress))
        return response.data?.data?.walletAddress
      } else {
        return {}
      }
    } catch (error: any) {
      dispatch(setWalletVerificationStatus('ERROR'))
      dispatch(setWalletVerificationMessage(error?.response?.data?.message))
    }
  }
)

export const getUserNFTs = createAsyncThunk<any, any>(
  'getUserNFTs',
  async ({ account }) => {
    try {
      const dataGraphPromise = client
        .query<TokenDataGraph>(tokensQuery(account), {})
        .toPromise()
      const dataReservoirPromise = getUserTokensReservoir(account)

      const [dataGraph, dataReservoir] = await Promise.all([
        dataGraphPromise,
        dataReservoirPromise,
      ])

      const tokenIdsGraph = dataGraph?.data?.tokens.map((t) => t.tokenId)

      const filteredResevoirTokens = dataReservoir?.filter(
        (f) => !tokenIdsGraph?.includes(f.token.tokenId)
      )
      const formattedReservoirTokens = filteredResevoirTokens.map((f) => ({
        id: f.token.tokenId,
        tokenId: f.token.tokenId,
        timestamp: f.ownership.acquiredAt,
        owner: account,
        mintedOrTransferred: '0',
        value: f.ownership.tokenCount,
        __typename: 'Token',
      }))

      const combinedTokens = [
        ...formattedReservoirTokens,
        ...(dataGraph?.data?.tokens ?? []),
      ]

      return {
        tokens: combinedTokens,
      }
    } catch (error) {
      console.log({ error })
    }
  }
)

export const updateProfile = createAsyncThunk<any, any>(
  'updateProfile',
  async (args, { dispatch }) => {
    const { firstName, lastName, profilePic, handleSuccessProfileUpdate } = args
    try {
      const response = await axiosInstance.post('user/update-profile', {
        firstName,
        lastName,
        profilePic,
      })

      if (handleSuccessProfileUpdate) handleSuccessProfileUpdate()
      return response?.data
    } catch (error: any) {
      dispatch(
        showToast({
          message: 'Error!, while updating the profile',
          toastType: ToastType.error,
        })
      )
    }
  }
)

export const getUserActivity = createAsyncThunk<any, any>(
  'getUserActivity',
  async ({ account }) => {
    try {
      const dataGraphPromise = client
        .query<UserTransfersData>(transfersQuery(account), {})
        .toPromise()
      const dataReservoirPromise = getUserActivityReservoir(account)

      const [dataGraph, dataReservoir] = await Promise.all([
        dataGraphPromise,
        dataReservoirPromise,
      ])

      const tokenIdsGraph = dataGraph?.data?.transfers.map((t) => t.tokenId)

      const filteredResevoirTokens = dataReservoir?.filter(
        (f) => !tokenIdsGraph?.includes(f.token.tokenId)
      )
      const formattedReservoirTokens = filteredResevoirTokens.map((f) => ({
        id: f.token.tokenId,
        operator: f.toAddress,
        from: f.fromAddress,
        to: f.toAddress,
        tokenId: f.token.tokenId,
        value: f.amount,
        blockNumber: '',
        blockTimestamp: f.timestamp,
        transactionHash: f.txHash,
        __typename: 'Transfer',
      }))

      const combinedTransfers = [
        ...formattedReservoirTokens,
        ...(dataGraph?.data?.transfers ?? []),
      ]

      return {
        transfers: combinedTransfers,
      }
    } catch (error) {
      console.log({ error })
    }
  }
)

export const getNftDetailsById = createAsyncThunk<any, any>(
  'getNftDetailsById',
  async ({ tokenIds }) => {
    try {
      const response = await axiosInstance.get(
        `nft/nft-detail?tokenIds=${[...tokenIds]}`
      )

      if (response.data) {
        return response?.data?.data
      }
    } catch (error: any) {}
  }
)

export const getNftActivityById = createAsyncThunk<any, any>(
  'getNftActivityById',
  async ({ tokenId }) => {
    try {
      const dataGraphPromise = client
        .query<TransfersResponse>(transfersByTokenId(tokenId), {})
        .toPromise()
      const dataReservoirPromise = getTokenActivityReservoir(tokenId)

      const [dataGraph, dataReservoir] = await Promise.all([
        dataGraphPromise,
        dataReservoirPromise,
      ])

      const tokenIdsGraph = dataGraph?.data?.transfers.map((t) => t.tokenId)

      const filteredResevoirTokens = dataReservoir?.filter(
        (f) => !tokenIdsGraph?.includes(f.token.tokenId)
      )
      const formattedReservoirTokens = filteredResevoirTokens.map((f) => ({
        id: f.token.tokenId,
        operator: f.toAddress,
        from: f.fromAddress,
        to: f.toAddress,
        tokenId: f.token.tokenId,
        value: f.amount,
        blockNumber: '',
        blockTimestamp: f.timestamp,
        transactionHash: f.txHash,
        __typename: 'Transfer',
      }))

      const combinedTransfers = [
        ...formattedReservoirTokens,
        ...(dataGraph?.data?.transfers ?? []),
      ]

      return {
        transfers: combinedTransfers,
      }
    } catch (error) {
      console.log({ error })
    }
  }
)

const userSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUserWalletAddress: (state, action) => {
      state.user.walletAddress = action.payload
    },
    setWalletVerificationStatus: (
      state,
      { payload }: { payload: WalletVerificationStatus }
    ) => {
      state.walletVerificationStatus = payload
    },
    setWalletVerificationMessage: (state, { payload }) => {
      state.walletVerificationMessage = payload
    },
    resetProfileUpdateMessage: (state) => {
      state.profileUpdateMessage = ''
    },
    removeTransferedNFTs: (state, action) => {
      const { tokenId } = action.payload
      state.userNFTs = state.userNFTs.filter(
        (nft: any) => nft?.tokenId !== tokenId
      )
    },
  },
  extraReducers: (builder) => {
    builder
      // getUserDetails
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.user = action.payload
        state.loading = false
      })
      .addCase(getUserDetails.rejected, (state) => {
        state.error = true
        state.loading = false
        state.user = {
          walletAddress: '',
          _id: '',
          email: '',
        }
      })
      // getUserNFTs
      .addCase(getUserNFTs.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(getUserNFTs.fulfilled, (state, action) => {
        state.userNFTs = action.payload?.tokens
        state.loading = false
      })
      .addCase(getUserNFTs.rejected, (state) => {
        state.error = true
        state.loading = false
      })
      // getUserActivity
      .addCase(getUserActivity.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(getUserActivity.fulfilled, (state, action) => {
        state.userActivity = action.payload?.transfers
        state.loading = false
      })
      .addCase(getUserActivity.rejected, (state) => {
        state.error = true
        state.loading = false
      })

      // getNftDetailsById
      .addCase(getNftDetailsById.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(getNftDetailsById.fulfilled, (state, action) => {
        state.nftDetailById = action.payload?.nftDetails
        state.loading = false
      })
      .addCase(getNftDetailsById.rejected, (state) => {
        state.error = true
        state.loading = false
      })
      // getNftActivityById
      .addCase(getNftActivityById.pending, (state) => {
        state.activityLoading = true
        state.error = undefined
      })
      .addCase(getNftActivityById.fulfilled, (state, action) => {
        state.nftActivityById = action.payload?.transfers
        state.activityLoading = false
      })
      .addCase(getNftActivityById.rejected, (state) => {
        state.error = true
        state.activityLoading = false
      })
      // updateProfile
      .addCase(updateProfile.pending, (state) => {
        state.loading = true
        state.error = undefined
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        const { profilePic } = action.meta.arg
        state.profileUpdateMessage = action.payload?.message
        state.user.profilePic = profilePic
        state.loading = false
      })
      .addCase(updateProfile.rejected, (state) => {
        state.error = true
        state.loading = false
      })
  },
})

export const {
  setUserWalletAddress,
  setWalletVerificationStatus,
  setWalletVerificationMessage,
  removeTransferedNFTs,
  resetProfileUpdateMessage,
} = userSlice.actions

export default userSlice.reducer

export const verifyingSignIn = async (
  account: any,
  walletType: WalletTypeEnum,
  dispatch: any,
  provider: any
) => {
  const message =
    'action= signIn&' +
    'address' +
    account +
    'signUp' +
    '&timestamp=' +
    new Date()?.toISOString()
  const signatureMessage = generateSignMessage(message)

  try {
    const signer = provider?.getSigner()
    const signatureHash = await signer.signMessage(signatureMessage)

    if (signatureHash) {
      dispatch(setWalletVerificationStatus('HASH'))
      dispatch(setWalletVerificationMessage('Verifying Signature!'))
      dispatch(
        connectWallet({
          address: account,
          walletType,
          signatureHash,
          signatureMessage,
        })
      )
    }
  } catch (error) {
    dispatch(setWalletVerificationStatus('ERROR'))
    dispatch(setWalletVerificationMessage('Transaction Declined!'))
  }
}

export const generateSignMessage = (message: string) => {
  const messageTemplate = `Welcome to VTR Connect Please sign in to affirm your acceptance of connecting wallet to VTR Connect.Rest assured, this request wont trigger any gas fees or blockchain transactions.${message}`

  return messageTemplate
}

export const logout = async () => {
  try {
    const response = await axiosInstance.post('user/logout')
    return response?.data
  } catch (error: any) {}
}
